import { useRenderBase } from "./useRenderBase"
import { useRenderLoading } from "./useRenderLoading"
import { useUseRenderText } from "./useRenderText"

export const useRenderTemplateV3 = async ({
  container,
  template,
  wishData,
  variant,
  debug = false,
  loadingOptions = {}
}) => {

  const {stage, wish, name, background,layer, placeholder, placeholderImageRef } = useRenderBase({
    container,
    template,
    wishData,
    variant,
    debug
  })

  watch(placeholderImageRef, (newVal, oldVal) => {
    wishData.guest.imageAttrs && placeholder.setAttrs({
      ...wishData.guest.imageAttrs,
    })
  })

  useUseRenderText({layer, wish, name, template})
  useRenderLoading({ loadingOptions, layer, placeholder, name, wish, background })

  return {
    stage
  }
}
